import bootstrap from "./bootstrap5.3.2.bundle.js";

document.addEventListener("DOMContentLoaded", function () {
  const activationForms = document.querySelectorAll(".activation-form");

  if (activationForms.length) {
    armActivationForms();
  }

  //Check if element with id signin-form exists
  if (document.getElementById("signin-form") !== null) {
    armLoginForm();
  }

  if (document.querySelector("table.today-laundry-bookings")) {
    armButtonAutoReload();
  }
});

function armLoginForm() {
  document.getElementById("signin-form").addEventListener("submit", (event) => {
    console.log("submit");
    if (!document.querySelector('input[name="tos"]').checked) {
      event.preventDefault();
      alert("Sie müssen die Nutzungsbedingungen akzeptieren.");
    }
  });
}

let activationDelayTimer;

function activationDelayHandler(modal, submitButton) {
  let delay = parseInt(modal._element.dataset.readDelay);
  if (delay > 0) {
    submitButton.textContent = `Bitte Hinweise lesen... (${delay})`;
    submitButton.disabled = true;
    delay--;
    modal._element.dataset.readDelay = delay;
    return;
  }

  submitButton.textContent = submitButton.dataset.originalText;
  submitButton.disabled = false;
  window.clearInterval(activationDelayTimer);
}

function armActivationForms() {
  let currentForm; // To keep track of the current form being processed
  let confirmationModalElement = document.getElementById("activation-modal");
  let confirmSubmitButtons =
    confirmationModalElement.querySelectorAll(".activation-submit"); // Select all submit buttons
  let confirmationModal = new bootstrap.Modal(confirmationModalElement);

  // Check if the modal has data-read-delay > 0. If so, we disable the submit button and enable it after the delay
  if (confirmationModalElement.dataset.readDelay > 0) {
    let submitButton = confirmationModalElement.querySelector(
      ".activation-submit.first-activation"
    );
    //Save the original text of the submit button to its data attribute
    submitButton.dataset.originalText = submitButton.textContent;
    submitButton.dataset.originalDelay =
      confirmationModalElement.dataset.readDelay;

    //Start the timer when the modal is shown
    confirmationModalElement.addEventListener("show.bs.modal", function () {
      activationDelayHandler(confirmationModal, submitButton);
      activationDelayTimer = window.setInterval(() => {
        activationDelayHandler(confirmationModal, submitButton);
      }, 1000);
    });

    //Clear the timer when the modal is hidden
    confirmationModalElement.addEventListener("hidden.bs.modal", function () {
      window.clearInterval(activationDelayTimer);
      confirmationModalElement.dataset.readDelay =
        submitButton.dataset.originalDelay;
    });
  }

  document.querySelectorAll(".activation-form").forEach(function (form) {
    form.addEventListener("submit", function (e) {
      e.preventDefault(); // Prevent form from submitting immediately
      currentForm = form; // Store the current form

      //Check if form.dataset.price is set and is > 0
      const paid = form.dataset.price && form.dataset.price > 0;

      if (paid) {
        confirmationModalElement
          .querySelectorAll(".activation-price")
          .forEach(function (span) {
            span.textContent = new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(form.dataset.price);
          });
      }

      //Hide all elements with class paid
      confirmationModalElement
        .querySelectorAll(".paid")
        .forEach(function (element) {
          element.style.display = paid ? "inline" : "none";
        });

      //In the confirmation modal hide all elements with class activation-dynamic
      confirmationModalElement
        .querySelectorAll(".activation-dynamic")
        .forEach(function (element) {
          element.style.display = "none";
        });

      //If, in the forms dataset reactivation is set to true, show the reactivation elements
      if (form.dataset.reactivation === "1") {
        confirmationModalElement
          .querySelectorAll(".reactivation")
          .forEach(function (element) {
            element.style.display = "block";
          });
      } else {
        //If not, show the first activation elements
        confirmationModalElement
          .querySelectorAll(".first-activation")
          .forEach(function (element) {
            element.style.display = "block";
          });
      }

      confirmationModal.show(); // Show the confirmation modal
    });
  });

  // Attach click event listener to all confirmation buttons
  confirmSubmitButtons.forEach(function (button) {
    button.addEventListener("click", function () {
      if (currentForm) {
        currentForm.submit();
      }
    });
  });
}

function armButtonAutoReload() {
  if (
    document.querySelector("table.today-laundry-bookings").dataset
      .buttonRefresh != "1"
  ) {
    return;
  }
  setInterval(fetchButtonStates, 10000);
}

function fetchButtonStates() {
  //Fetch button states from server
  fetch("/laundry/buttonstates")
    .then((response) => response.json())
    .then((data) => {
      for (const entry of data) {
        let row = document.querySelector(
          `table.today-laundry-bookings tr[data-order-id="${entry.id}"]`
        );
        if (!row) {
          //Reload page if booking is not known locally
          location.reload();
        }

        let washerActivation = false,
          dryerActivation = false,
          washerHint = false,
          dryerHint = false,
          cancel = false;

        if (entry.wash == "act") {
          washerActivation = true;
        } else if (entry.wash == "hint") {
          washerHint = true;
        }

        if (entry.dry == "act") {
          dryerActivation = true;
        } else if (entry.dry == "hint") {
          dryerHint = true;
        }

        if (entry.cancel) {
          cancel = true;
        }

        row
          .querySelector('[data-order-button="washer-activation"]')
          .classList.toggle("d-none", !washerActivation);
        row
          .querySelector('[data-order-button="washer-hint"]')
          .classList.toggle("d-none", !washerHint);
        row
          .querySelector('[data-order-button="dryer-activation"]')
          .classList.toggle("d-none", !dryerActivation);
        row
          .querySelector('[data-order-button="dryer-hint"]')
          .classList.toggle("d-none", !dryerHint);
        row
          .querySelector('[data-order-button="cancel"]')
          .classList.toggle("d-none", !cancel);
      }
    });
}
